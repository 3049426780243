const burger = $('#burger');
const menu = $('#menu');
const menuItem = $('#menu > li > a');

burger.click(() => {
  burger.toggleClass('active');
  menu.toggleClass('active');
});

menuItem.click(() => {
  burger.removeClass('active');
  menu.removeClass('active');
});