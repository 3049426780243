function gutscheinCode(el) {
	let error = false;
	const totalPreis = document.querySelector("#bestellungGesamt span");
	const gutscheinError = document.querySelector(".gutschein-error");
	const artikel = ["POS12191", "POS12190", "POS12181", "POS12180"];
	const value = el.parentNode.querySelector("[name=gutschein]").value;
	if (value === "RS1023" || value === "BT1023") {
		artikel.map(a => {
			if (document.querySelector("[data-artikel=" + a + "]")) {

				const einzelPreis = document.querySelector("[data-artikel=" + a + "][name*=preisForm]");
				einzelPreis.value = "0 €";
				const gesamtPreis = document.querySelector("[data-artikel=" + a + "][name*=totalForm]");
				gesamtPreis.value = "0 €";
			}

		})
		totalPreis.innerHTML = "0";
	} else {

		error = "<p class='engpass'>Der Gutscheincode ist leider ungültig.</p>"
	}
	if (error) {
		gutscheinError.innerHTML = error;
	} else {
		gutscheinError.innerHTML = "";
	}
}



// VARS

const showCart = $('#showCart');
const hideCart = $('#closePopup');
const warenkorb = $('#warenkorbPopup');
let warenkorbListe = document.getElementById('warenkorb').children.length;
const cartCountIcon = $('#counter');
let shoppingCartIconNav = $('.shoppingCartIcon');




// Warenkorb anzeigen und ausblenden

showCart.click((event) => {
	event.preventDefault();
	warenkorb.fadeIn(200);

});

hideCart.click((event) => {
	event.preventDefault();
	warenkorb.slideUp(200);
});


// Wareonkorb Notification anzeigen

if (warenkorbListe === 0) {
	cartCountIcon.hide();

} else {
	shoppingCartIconNav.show();
	cartCountIcon.show();
}






// ***************************************************
// Shopping Cart functions



var shoppingCart = (function () {
	// Private methods and properties
	var cart = [];

	function Item(name, id, preis, menge, dekopaket, input = true, max, min = 1) {
		this.name = name
		this.preis = preis
		this.id = id
		this.input = input
		this.menge = menge
		this.dekopaket = dekopaket
		this.max = max
		this.min = min
		this.total = menge * preis
	}

	// Warenkorb speichern

	function saveCart() {


		localStorage.setItem("shoppingCart", JSON.stringify(cart));
		$('#counter').html(warenkorbListe);
		if (warenkorbListe === 0) {
			$('#emptyCart').html('<p class="warenkorbSmall">Keine Artikel im Warenkorb</p>');
			cartCountIcon.hide();
		} else {
			$('#emptyCart').html('');
			cartCountIcon.show();
		}
	}

	// Warenkorb laden

	function loadCart() {
		cart = JSON.parse(localStorage.getItem("shoppingCart"));
		if (cart === null) {
			cart = []
		}
		warenkorbListe = cart.length;
		$('#counter').html(warenkorbListe);
		if (warenkorbListe === 0) {
			$('#emptyCart').html('<p class="warenkorbSmall">Keine Artikel im Warenkorb</p>');
			cartCountIcon.hide();
		} else {
			$('#emptyCart').html('');
			cartCountIcon.show();
		}
	}





	loadCart();



	// Public methods and properties
	var obj = {};


	// Menge ändern

	obj.changeCount = function (id, menge) {

		for (var i in cart) {
			if (cart[i].id === id) {
				// if (menge <= cart[i].max) {
				// 	cart[i].menge = menge;
				// }
				cart[i].menge = menge;
				// console.log(cart[i])

				// console.log('================ changeCount ===============');
				// console.log('Menge = ' + cart[i].menge);
				break;
			}
		}
		saveCart();

	}


	// Item hinzufügen

	obj.addItemToCart = function (name, id, preis, menge, dekopaket, input = true, max, min = 1) {
		shoppingCartIconNav.show();
		warenkorb.fadeIn(200);
		for (var i in cart) {
			if (cart[i].name === name) {
				return;
			}

		}

		var item = new Item(name, id, preis, menge, dekopaket, input, max, min);
		cart.push(item);
		warenkorbListe++;

		saveCart();
	};



	// Item aus Warenkorb entfernen

	obj.removeItemFromCartAll = function (name) { // removes all item name
		for (var i in cart) {
			if (cart[i].name === name) {
				cart.splice(i, 1);
				break;
			}
		}
		warenkorbListe--;
		saveCart();
	};

	// Warenkorb leeren


	obj.clearCart = function () {
		cart = [];
		saveCart();
	}


	// Anzahl Artikel im Warenkorb


	obj.countCart = function () { // -> return total count
		var totalCount = cart.length;

		return totalCount;
	};


	// Gesamtkosten Warenkorb


	obj.totalCart = function () { // -> return total cost
		var totalCost = 0;

		for (var i in cart) {
			if (isNaN(cart[i].preis)) {
				cart[i].preis = 0;
			}
			totalCost += cart[i].preis * cart[i].menge;
			saveCart();
		}
		// return totalCost;
		$("#totalCartWarenkorb").html(totalCost);
		$("#bestellungGesamt span").html(totalCost);


	}



	// Warenkorb Liste


	obj.listCart = function () { // -> array of Items
		var cartCopy = [];
		for (var i in cart) {

			var item = cart[i];
			var itemCopy = {};
			for (var p in item) {
				itemCopy[p] = item[p];
			}
			if (isNaN(item.preis)) {
				itemCopy.total = '-';
			} else {
				itemCopy.total = (item.preis * item.menge).toFixed(0);

			}
			cartCopy.push(itemCopy);
		}
		return cartCopy;
	};

	// ----------------------------
	return obj;
})();




function bling() {
	$('.shoppingCartAdd').show();
	$('.shoppingCartAdd').addClass('bling');
	setTimeout(function () {
		$('.shoppingCartAdd').hide();
		$('.shoppingCartAdd').removeClass('bling');
	}, 200);
};



$(".add-to-cart").click(function (event) {
	event.preventDefault();
	bling();
	var min = 1;
	$(this).addClass('added')
	var name = $(this).attr("data-name");
	var max = false;
	if ($(this).attr("data-maxmenge")) { 
		max = $(this).attr("data-maxmenge");
	}
	if ($(this).attr("data-min")) { 
		min = $(this).attr("data-min");
	}
	var id = $(this).attr("data-id");
	var preis = Number($(this).attr("data-preis"));
	var menge = Number($(this).attr("data-menge"));
	var dekopaket = '';
	if ($(this).attr("data-dekopaket")) {
		dekopaket = $(this).attr("data-dekopaket");
	}
	var showInput = true
	if (isNaN(menge)) { 
		menge = 1
		showInput = false
	}

	shoppingCart.addItemToCart(name, id, preis, menge, dekopaket, showInput, max, min);
	displayCart();
});

// $("#clear-cart").click(function (event) {
//   shoppingCart.clearCart();
//   displayCart();
// });









function displayCart() {
	var cartArray = shoppingCart.listCart();
	console.log(cartArray);
	var output = "";
	var outputBestellung = "";
	var outputForm = "";

	// var outputSummary = '';
	var outputSummary = '<tr><td>Bezeichnung</td><td>Menge</td><td>Preis</td><td>Artikelnummern</td></tr>';

	for (var i in cartArray) {
		output += '<li id="cartItem' + i + '">';
		output += '<span id="name">' + cartArray[i].name + '</span>'
		output += '<span id="artikelnummer" class="artikel">' + cartArray[i].id + '</span>'
		if (cartArray[i].input !== false) {
			output += '<input data-id=' + cartArray[i].id + ' data-total="" data-preis="' + cartArray[i].preis + ' €" type="number" min="'+ cartArray[i].min +'" data-max="'+ cartArray[i].max +'" class="mengeArtikel" id="Anzahl' + i + '" value="' + cartArray[i].menge + '">'
		} else {
			output += '<div class="mengeArtikel"></div>'
		}
		output += '<span id="preis" class="cartpreis">' + cartArray[i].total + ' €</span>'
		output += '<button class="delete" data-name="' + cartArray[i].name + '"></button></li>'


		outputBestellung +=
			'<div class="soft-row"><div class="spalte cartListItems s1" id="artikel-' + i + '"><h5>' +
			cartArray[i].name +
			'</h5></div><div class="spalte cartListItems s1" id="artikelnummer-' + i + '"><h5>' +
			cartArray[i].id +
			'</h5></div><div class="spalte cartListItems s2" id="preis-' + i + '"><h5>' +
			cartArray[i].preis +
			' €</h5></div><div class="spalte cartListItems s2" id="menge-' +
			i + '"><h5>' +
			cartArray[i].menge + '</h5></div><div class="spalte cartListItems s2" id="total-' +
			i + '"><h5>' +
			cartArray[i].total + ' €</h5></div></div>';

		outputForm += '<div class="soft-row">';
		outputForm += '<input class="spalte cartListItems s1" type="text" readonly name="artikelForm[]" value="' + cartArray[i].name + '" data-artikel="' + cartArray[i].id + '">';
		outputForm += '<input class="spalte cartListItems s1" type="text" readonly name="artikelnummerForm[]" value="' + cartArray[i].id + '" data-artikel="' + cartArray[i].id + '">';
		outputForm += '<input class="spalte cartListItems s2" type="text" readonly name="preisForm[]" value="' + cartArray[i].preis + ' €" data-artikel="' + cartArray[i].id + '">';
		outputForm += '<input class="spalte cartListItems s2" type="text" readonly name="mengeForm[]" value="' + cartArray[i].menge + ' " data-artikel="' + cartArray[i].id + '">';
		outputForm += '<input class="spalte cartListItems s2" type="text" readonly name="totalForm[]" value="' + cartArray[i].total + ' €" data-artikel="' + cartArray[i].id + '">';
		outputForm += '</div>';



		if (cartArray[i].name.indexOf('Hauswurfsendung') > -1) {
			let pwsHint = $('#pwsHint');

			let hasPws = '<p><sup>*</sup>Für die Abstimmung Ihrer Personalisierung und zur Beratung des Versandes kontaktiert Sie unsere Agentur. Die Kosten sind abhängig von der Auflage (Mindestauflage: 250 Exemplare).</p>';
			let hasNoPws = '';
			pwsHint.html(hasPws);
		}

		if (cartArray[i].name.indexOf('Leaderboard') > -1 || cartArray[i].name.indexOf('Skyscraper') > -1) {
			let banner = $('#bannerHint');
			let hasBanner = '';
			hasBanner += '<p><sup>**</sup>Für die Abstimmung und zur Personalisierung kontaktiert Sie unsere Agentur.</p>';
			banner.html(hasBanner);
		}

		// outputSummary += `Artikel: ${cartArray[i].name}, Artikelnummer: ${cartArray[i].id}, Artikelpreis: ${cartArray[i].preis}, Menge: ${cartArray[i].menge}, Gesamtpreis Artikel: ${cartArray[i].total}\n`
		outputSummary += `<tr><td>${cartArray[i].name}</td><td>${cartArray[i].menge}</td><td>${cartArray[i].preis}</td><td>${cartArray[i].id} ${cartArray[i].dekopaket}</td></tr>`

	}
	outputForm += `<input type="hidden" name="kundenbestellung" value="${outputSummary}">`
	shoppingCart.totalCart();
	$("#warenkorb").html(output);
	$("#warenkorbCheckout").html(outputForm);

}

$('#clear-cart').on('click', function (event) {
	shoppingCart.clearCart();
	displayCart()
});


$("#warenkorb").on("click", ".delete", function (event) {
	var name = $(this).attr("data-name");
	shoppingCart.removeItemFromCartAll(name);
	displayCart();
});




$(document).on("change", '.mengeArtikel', function (event) {
	let name = $(this).attr("data-id");
	let max = parseInt($(this).attr("data-max"));
	let menge = parseInt($(this).val());
	if (!isNaN(max) && menge > max) {
		// console.log(max);
		menge = max;
	}

	shoppingCart.changeCount(name, menge);

	displayCart();
});





displayCart();