const gnStudie = {
	submitDownload(title, id) {
		// alert(`${title}: ${id}`)
		const url = "../../includes/updateUser.php"

		const data = {
			id: id,
			type: 'download',
			title: title
		}

		

		const formData = new FormData()
		let fData = JSON.stringify(data)
		formData.append('update', fData)

	

		axios.post(url, formData).then(res => {
			console.log(res);
		})
			.catch(err => console.log(err)) 
		
			console.log(data);

			

	}
}