

$(document).ready(function () {

  "use strict";
  $('.toggle dt').stop().click(function () {
    if ($(this).hasClass('active')) {
      $(this).removeClass('active');
      $(this).next().removeClass('active');
    } else {
      $(this).parent().children().removeClass('active');
      $(this).addClass('active');
      if ($(this).next().is('dd')) {
        $(this).parent().children('dd').removeClass('active');
        $(this).next().addClass('active')
      }
    }
  });

});







