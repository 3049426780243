$(document).ready(function () {

  function move(direction) {
    var carousel = $('.sliderContainer');
    if (!carousel.is(':animated')) {
      if (direction === 'left') {
        carousel.animate({
          left: -100 + '%'
        }, 1000, function () {
          carousel.find(".slide:last").after(carousel.find(".slide:first"));
          carousel.css('left', 0);
        });
      } else {
        carousel.find('.slide:first').before(carousel.find('.slide:last'));
        carousel.css('left', '-100%').animate({
          left: 0
        }, 1000);
      }
    }
  }

  var t = setInterval(function () {
    move('left');
  }, 10000);


});